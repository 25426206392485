import React, { useState } from 'react'
import { Form, Input, Space, Button, message } from 'antd'
import { trpc } from '../../utils/trpc'
import { usePageOutletContext } from '../../page-outlet-context'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

export const AssemblyStations: React.FC = () => {
  const { salesGroupFilter, sourceFilter, workspace } = usePageOutletContext()

  const [changed, setChanged] = useState(false)
  const [extraInputs, setExtraInputs] = useState<number>(0)

  const [form] = Form.useForm()

  const queryResult = trpc.assemblyStation.getAssemblyStation.useQuery({
    workspaceId: workspace.serialId,
    salesGroupId: salesGroupFilter.salesGroupId
  }, {
    onSuccess: (data) => {
      form.resetFields()
      if(!data) {
        setExtraInputs(1)
      } else {
        const newValues = data.assemblies.map((assembly) => {
          return assembly.products.reduce((value, current) => {
            return value.length > 0
              ? `${value}, ${current.productId}`
              : current.productId.toString()
          }, '')
        })

        form.setFieldsValue(newValues)
        setExtraInputs(0)
      }

    }
  })

  const upsertAssemblyStationsMutation = trpc.assemblyStation.upsertAssemblyStations.useMutation()

  const onFinish = (stationsInput: Record<number, string>) => {
    const nonEmptyInputs = Object.values(stationsInput).filter(input => input && input.trim() !== '')

    upsertAssemblyStationsMutation.mutate({
      assemblies: nonEmptyInputs.map(stationInput => inputToProducts(stationInput, sourceFilter.serialId)),
      workspaceId: workspace.serialId,
      salesGroupId: salesGroupFilter.salesGroupId
    }, {
      onSuccess: () => {
        message.success('Estanterías actualizadas')
      }
    })
  }

  const totalAssembies = [
    ...(queryResult.data?.assemblies || []),
    ...Array.from({ length: extraInputs }, (_, index) => index)
  ]

  return (
    <Form layout="vertical" form={form} onChange={() => setChanged(true)} onFinish={onFinish}>
      {totalAssembies.map((_, index) => {
        return (
          <Form.Item
            label={`Estantería ${index + 1}`}
            name={index}
            key={index}
          >
            <Input
              pattern="[0-9 ,]+"
              id={index.toString()}
            />
          </Form.Item>
        )
      })}
      <Form.Item>
        <Space>
          <Button
            type="default"
            onClick={() => extraInputs > 0 && setExtraInputs(extraInputs - 1)}
            disabled={extraInputs === 0}
          >
            <MinusOutlined />
          </Button>
          <Button
            type="default"
            onClick={() => setExtraInputs(extraInputs + 1)}
          >
            <PlusOutlined />
          </Button>
        </Space>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!changed}
          >
            Guardar
          </Button>
        </Space>

      </Form.Item>
    </Form>
  )
}

const inputToProducts = (productsIds: string, sourceId: number) => {
  const products = productsIds.split(',')
    .map(item => Number(item.trim()))
    .filter(item => !!item)
    .map(productId => ({
      sourceId,
      productId
    }))

  return { products }
}
